$(function () {

    $('a[href^="#"]').click(function () {
        var speed = 500;
        var href = $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top;
        $('html, body').not(':animated').animate({scrollTop: position}, speed, 'swing');
        return false;
    });

    $('nav img').hover(function () {
        $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
    }, function () {
        if (!$(this).hasClass('currentPage')) {
            $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
        }
    });

    if ($('#back-to-top').length) {
        var scrollTrigger = 100,
            backToTop = function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scrollTrigger) {
                    $('#back-to-top').addClass('show');
                } else {
                    $('#back-to-top').removeClass('show');
                }
            };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
    }

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 100) {
            $('#back-to-top').fadeIn('fast');
        } else {
            $('#back-to-top').fadeOut('fast');
        }
        scrollHeight = $(document).height();
        scrollPosition = $(window).height() + $(window).scrollTop();
        footHeight = $('footer').innerHeight();
        if (scrollHeight - scrollPosition <= footHeight , window.matchMedia('(min-width:769px)').matches) {
            $('#back-to-top').css({
                'position': 'absolute',
                'bottom': footHeight + 40
            });
        } else {
            $('#back-to-top').css({
                'position': 'fixed',
                'bottom': '20px'
            });
        }
    });

    $('.plus').on('click', function () {
        $(this).next().slideToggle();
        $(this).toggleClass('active');
    });

    $('#accordion_sp').click(function () {
        $('#accordion_sp_in').slideToggle();
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            $('img', this).attr('src', $('img', this).attr('src').replace('_nav', '_close'));
        } else {
            $('img', this).attr('src', $('img', this).attr('src').replace('_close', '_nav'));
        }
    });

    if (typeof Swiper !== 'undefined') {
        new Swiper('.swiper-container', {
            loop: true,
            autoplay: 4000,
            simulateTouch: true,
            resizeReInit: true,
            slidesPerView: '1',
            pagination: '.swiper-pagination',
            paginationClickable: true,
            paginationType: 'bullets',
            breakpoints: {
                2000: {
                    centeredSlides: true,
                    slidesPerGroup: 1,
                    spaceBetween: 0,
                    slidesPerView: '3',
                },
                640: {
                    spaceBetween: 0,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                }
            },
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    if (typeof $.fn.slick !== 'undefined') {
        $('.center-item').slick({
            infinite: true,
            dots: true,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 2800,
            speed: 1000,
            responsive: [{
                breakpoint: 640,
                settings: {
                    centerMode: false,
                }
            }]
        });
    }

    $('.good-btn').on('click', function () {
        var _self = this;
        var href = $(_self).closest('a').attr('href');
        if (href === 'javascript:void(0);') {
            idval = $(this).data('id');
            $.appApi.addGoods({id: idval}).done(function (result, textStatus, jqXHR) {
                var $target = $(_self).closest('a').find('.count');
                $target.text(Number($target.text()) + 1);
                alert('「いいね」しました');
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status === 409) {
                    alert('「いいね」済みです');
                } else {
                    console.warn(jqXHR);
                }
            });
        } else {
            return true;
        }
    });

    $('.favorite').on('click', function () {
        $.appApi.addFavorites({id: $(this).data('id')}).done(function (result, textStatus, jqXHR) {
            alert('「お気に入り」に登録しました');
        }).fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status === 409) {
                alert('「お気に入り」に登録済みです');
            } else {
                console.warn(jqXHR);
            }
        });
    });

});
